import { useField } from 'formik';
import * as React from 'react';

import { InputNumber } from '../Input';
import { Label } from '../Label';

export const NumberField = props => {
  const { label, name, onChange } = props;
  const [field, meta, helpers] = useField(name);

  return (
    <>
      {typeof label === 'string' ? <Label>{label}</Label> : label}
      <InputNumber
        {...field}
        {...props}
        invalid={!!(meta.touched && meta.error)}
        allowNegative={false}
        onValueChange={({ floatValue }) => {
          helpers.setValue(floatValue);
        }}
        onChange={() => {}}
      />
    </>
  );
};
