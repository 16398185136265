import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Col, Row } from '../../../../components/Grid';
import { Button } from '../../../../components/Button';
import { Icon } from '../../../../components/Icon';

interface Props {}

export function Navigation(props: Props) {
  const { t } = useTranslation();
  const { lessonId } = useParams<{ lessonId: string }>();

  return (
    <Row>
      <Col xs={12} display={{ xs: 'block', lg: 'none' }}>
        <Row>
          <Col>
            <Button whiteBorder to={`/lesson/${lessonId}/flashcards`} block>
              <Row gutter={0.5}>
                <Col gutter={0.5}>
                  <Icon name="stack" />
                </Col>
                <Col gutter={0.5} display={{ xs: 'none', md: 'block' }}>
                  {t('label.flashcard', { count: 2 })}
                </Col>
              </Row>
            </Button>
          </Col>
          <Col>
            <Button whiteBorder to={`/lesson/${lessonId}/audio`} block>
              <Row gutter={0.5}>
                <Col gutter={0.5}>
                  <Icon name="microphone" />
                </Col>
                <Col gutter={0.5} display={{ xs: 'none', md: 'block' }}>
                  {t('label.audio')}
                </Col>
              </Row>
            </Button>
          </Col>
          <Col>
            <Button whiteBorder to={`/lesson/${lessonId}/boards`} block>
              <Row gutter={0.5}>
                <Col gutter={0.5}>
                  <Icon name="document-text" />
                </Col>
                <Col gutter={0.5} display={{ xs: 'none', md: 'block' }}>
                  {t('label.note', { count: 2 })}
                </Col>
              </Row>
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
