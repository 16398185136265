import React from 'react';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';

import { Col, Row } from '../../../components/Grid';
import { Button } from '../../../components/Button';
import { Container } from '../../../components/Container';
import { GameType } from '../../../../types/Game';
import { media } from '../../../../styles/media';

interface Props {
  values: {
    classes: { [key: string]: string[] };
    language: string;
    cardsCount: number;
  };
  gameType: GameType;
}

export function Footer(props: Props) {
  const { values, gameType } = props;
  const lessonsId = Object.values(values.classes)?.flat();
  const limit = values.cardsCount;
  const count = lessonsId?.length;
  const { t } = useTranslation();
  const disabled = gameType === GameType.MockTest && limit === 0;

  return (
    <Wrapper>
      <Container $fixed>
        <Row alignItems="center" flexWrap="nowrap" gutter={1}>
          <Col xs="auto">
            <Row justifyContent="center">
              <b>{t('message.lessonsSelected', { count })}</b>
            </Row>
          </Col>
          <Col>
            <Button
              disabled={disabled}
              type="submit"
              yellow
              to={`/play/${gameType}/${lessonsId.join(',')}?lang=${
                values.language
              }&limit=${limit}`}
            >
              {t('button.play')}
            </Button>
          </Col>
        </Row>
      </Container>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  position: fixed;
  right: 0;
  left: 0;
  bottom: 0;
  background: var(--white);
  min-height: 56px;
  padding: 0.375rem 0;

  ${Container}:after {
    content: none;
  }

  ${media.md} {
    padding: 1rem 0;
    min-height: 76px;
  }
`;
