import React, { useCallback, useEffect } from 'react';
import { useField } from 'formik';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';

import { useAudioRecorder } from '../../../../../hooks/useAudioRecorder';
import { Icon } from '../../../../../components/Icon';
import { AudioButton } from '../../../../../components/AudioButton';
import { PulseIconButton } from '../../../../../components/PulseIconButton';
import {
  LessonCardContent,
  LessonCardFooter,
} from '../../../../../components/LessonCard';
import { Col, Row } from '../../../../../components/Grid';
import { Button } from '../../../../../components/Button';

const reader = new FileReader();

export function AudioField(props) {
  const { label, name } = props;
  const [field, meta, helpers] = useField<
    { type?: string; data?: string } | undefined
  >(name);
  const { t } = useTranslation();

  const resetValue = useCallback(() => {
    helpers.setValue(undefined);
  }, []);

  const onFileRead = event => {
    helpers.setValue({
      ...field.value,
      data: event.target.result,
    });
  };

  useEffect(() => {
    reader.addEventListener('load', onFileRead);
    return () => {
      reader.removeEventListener('load', onFileRead);
    };
  }, []);

  const onRecordingComplete = (blob: Blob) => {
    helpers.setValue({
      ...field.value,
      type: blob.type,
    });
    reader.addEventListener('load', onFileRead);
    reader.readAsDataURL(blob);
  };

  const { startRecording, stopRecording, isRecording } = useAudioRecorder({
    onRecordingComplete,
  });

  return (
    <>
      <LessonCardContent>
        <Row justifyContent="center">
          <Col>
            {field.value?.data ? (
              <AudioButton src={field?.value?.data} />
            ) : (
              <PulseIconButton
                withPadding
                $active={isRecording}
                onClick={isRecording ? stopRecording : startRecording}
              >
                <Icon name="microphone" />
              </PulseIconButton>
            )}
          </Col>
        </Row>
      </LessonCardContent>
      <LessonCardFooter $hidden={!field.value?.data}>
        <Row justifyContent="center">
          <Col>
            <Button blue onClick={resetValue}>
              {t('button.replace')}
            </Button>
          </Col>
        </Row>
      </LessonCardFooter>
    </>
  );
}
