import styled, { css } from 'styled-components/macro';

import { forwardRef, PropsWithChildren, useContext } from 'react';
import { ifProp, prop } from 'styled-tools';
import { LessonCardContext } from './LessonCardContext';
import { LessonCardFront } from './LessonCardFront';
import { LessonCardBack } from './LessonCardBack';

export const LessonCard = forwardRef<HTMLDivElement, PropsWithChildren>(
  (props, ref) => {
    const { children } = props;

    return (
      <Wrapper ref={ref}>
        <svg viewBox="0 0 50 70" height="100%" />
        <FlipContainer>{children}</FlipContainer>
      </Wrapper>
    );
  },
);

const FlipContainer = (props: PropsWithChildren) => {
  const { flipped, gameMode } = useContext(LessonCardContext);
  return (
    <Container flipped={flipped} gameMode={gameMode}>
      {props.children}
    </Container>
  );
};

export const Wrapper = styled.div`
  height: 100%;
  position: relative;
`;

export const Container = styled.div<{ flipped?: boolean; gameMode?: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transform-style: preserve-3d;
  transform: rotateY(${ifProp('flipped', 180, 0)}deg);
  background: var(--white);
  border-radius: 0.5rem;
  ${ifProp(
    'gameMode',
    '',
    css`
      transition: 0.4s;
    `,
  )}

  ${LessonCardBack} {
    pointer-events: none;
  }

  ${ifProp(
    'flipped',
    css`
      ${ifProp(
        'gameMode',
        css`
          transition: 0.4s;
        `,
      )}

      ${LessonCardFront} {
        pointer-events: none;
      }
      ${LessonCardBack} {
        pointer-events: auto;
      }
    `,
  )}
`;
