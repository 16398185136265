import React from 'react';
import styled from 'styled-components/macro';
import { useRxData } from 'rxdb-hooks';
import { useParams, useSearchParams } from 'react-router-dom';

import { GameContextProvider } from './GameContext';
import { GameType } from '../../../types/Game';
import { LessonDocType } from '../../../database/schemas/lesson';
import { CollectionName } from '../../../database/types';
import { FlashCardDocType } from '../../../database/schemas/flashcard';
import { Game } from './Game';
import { Wrapper } from '../../components/PageWrapper';
import _shuffle from 'lodash/shuffle';

export function GamePage() {
  const params = useParams<{
    gameType: GameType;
    lessonsId: string;
  }>();
  const lessonsId = params.lessonsId?.split(',') || [];
  const gameType = params.gameType as GameType;
  let [searchParams] = useSearchParams();
  const limit = searchParams.get('limit')
    ? Number(searchParams.get('limit'))
    : undefined;

  const { result: lessons } = useRxData<LessonDocType>(
    CollectionName.Lessons,
    collection => collection.findByIds(lessonsId),
  );

  const { result: flashcards, isFetching } = useRxData<FlashCardDocType>(
    CollectionName.FlashCards,
    collection =>
      collection.find({
        selector: {
          lesson_id: {
            $in: lessonsId,
          },
        },
      }),
  );
  const flashcardsShuffled = _shuffle(flashcards);
  const flashcardsSliced =
    gameType === GameType.MockTest
      ? flashcardsShuffled.slice(0, limit)
      : flashcardsShuffled;

  return (
    <GameContextProvider>
      <PageWrapper>
        <Game
          flashcards={flashcardsSliced}
          lessons={lessons}
          isFetching={isFetching}
        />
      </PageWrapper>
    </GameContextProvider>
  );
}

const PageWrapper = styled(Wrapper)`
  padding-bottom: 0 !important;
`;
